import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import useClientSettings from 'hooks/useClientSettings';
import useUserActivity from 'hooks/useUserActivity';

const STOP_INDICATOR = '||[STOP]||';

function useAIAssistFetch() {
    const fetchAuth = useFetchAuth();
    const url = '/ai_assist/templates/get';

    return useQuery(['ai_assist_fetch'], () => fetchAuth(url), {
        retry: 3,
        retryDelay: 1000,
        refetchOnMount: false,
        onError: (err) => {
            Sentry.captureException(err, {
                tags: ['ai_assist_fetch']
            });
            console.log(err);
        }
    });
}

export function useGenerateToneOfVoice() {
    const fetchAuth = useFetchAuth();
    const { addUserActivity } = useUserActivity();
    const [isLoadingMutation, setIsLoading] = useState(false);
    const [stream, setStream] = useState();
    const [logId, setLogId] = useState();
    const [error, setError] = useState();
    const [isStreaming, setIsStreaming] = useState(false);
    const {
        data: { ai_assist_settings: aiAssistSettingsData = {}, email },
        isLoading: isLoadingClientSettings,
        UpdateClientSettings
    } = useClientSettings({
        params: 'ai_assist_settings,email'
    });
    const { ai_assist_settings } = aiAssistSettingsData;

    async function updateAIAssistSettings(payload) {
        const { tone_of_voice_name, tone_of_voice, is_ai_assist_settings_client_level } = payload;
        let updatePayload = {};
        if (tone_of_voice && ai_assist_settings.tone_of_voice !== tone_of_voice) {
            updatePayload.tone_of_voice = {
                [tone_of_voice_name]: tone_of_voice
            };
        }
        if (JSON.stringify(updatePayload) === '{}') {
            return;
        }
        updatePayload = {
            is_ai_assist_settings_client_level,
            ...ai_assist_settings,
            ...updatePayload
        };
        try {
            await UpdateClientSettings({
                ai_assist_settings: JSON.stringify(updatePayload)
            });
        } catch (err) {
            Sentry.captureException(err, {
                tags: ['ai_assist_update_tone_of_voice']
            });
            console.log(err);
        }
    }

    function generateToken(payload) {
        return fetchAuth('/ai_assist/generate_token', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    async function generateResponse(payload) {
        try {
            addUserActivity({
                event_name: 'ai_assist_generate_tone_of_voice',
                event_payload: payload
            });
            const { success, response: tokenResponse, message } = await generateToken(payload);
            if (!success) {
                throw new Error(message);
            }

            setStream();
            setIsLoading(true);
            setIsStreaming(true);
            const log_id = tokenResponse[0].log_id;
            const ai_token = tokenResponse[0].ai_token;
            payload.cognito_email = email;
            const response = await fetch(process.env.REACT_APP_AI_ASSIST_STREAM_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    log_id,
                    ai_token,
                    tone_of_voice: payload.tone_of_voice
                })
            });
            const streamResponse = response.body;
            if (!streamResponse) {
                setIsLoading(false);
                setIsStreaming(false);
                return;
            }

            const reader = streamResponse.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let content = '';
            setLogId(log_id);

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);
                content = content + chunkValue;
                const streamContent = content;
                setStream(streamContent);
            }
            const contentResult = content;
            await updateAIAssistSettings({
                is_ai_assist_settings_client_level: payload.is_ai_assist_settings_client_level,
                tone_of_voice_name: payload.tone_of_voice_name,
                tone_of_voice: contentResult
            });
        } catch (ex) {
            console.log(ex);
            setError(ex.message);
        } finally {
            setIsLoading(false);
            setIsStreaming(false);
        }
    }

    const isLoading = isLoadingClientSettings || isLoadingMutation;

    return {
        isLoading,
        error,
        mutate: generateResponse,
        stream,
        logId,
        isStreaming
    };
}

function useAIAssistPost() {
    const fetchAuth = useFetchAuth();
    const { addUserActivity } = useUserActivity();
    const [isLoadingMutation, setIsLoading] = useState(false);
    const [stream, setStream] = useState();
    const [logId, setLogId] = useState();
    const [isStreaming, setIsStreaming] = useState(false);
    const [canContinue, setCanContinue] = useState(false);
    const {
        data: { ai_assist_settings: aiAssistSettingsData = {}, email },
        isLoading: isLoadingClientSettings,
        UpdateClientSettings
    } = useClientSettings({
        params: 'ai_assist_settings,email'
    });
    const { ai_assist_settings } = aiAssistSettingsData;

    async function updateAIAssistSettings(payload) {
        const { organization_name, organization_cause, org_specific_language } = payload;
        let updatePayload = {};
        if (organization_name && ai_assist_settings.organization_name !== organization_name) {
            updatePayload.organization_name = organization_name;
        }
        if (organization_cause && ai_assist_settings.organization_cause !== organization_cause) {
            updatePayload.organization_cause = organization_cause;
        }
        if (
            org_specific_language &&
            ai_assist_settings.org_specific_language !== org_specific_language
        ) {
            updatePayload.org_specific_language = org_specific_language;
        }
        if (JSON.stringify(updatePayload) === '{}') {
            return;
        }
        updatePayload = {
            ...ai_assist_settings,
            ...updatePayload
        };
        try {
            await UpdateClientSettings({
                ai_assist_settings: JSON.stringify(updatePayload)
            });
        } catch (err) {
            Sentry.captureException(err, {
                tags: ['ai_assist_update_client_settings']
            });
            console.log(err);
        }
    }

    function generateToken(payload) {
        return fetchAuth('/ai_assist/generate_token', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    async function generateResponse(payload) {
        try {
            addUserActivity({
                event_name: 'ai_assist_generate_response',
                event_payload: payload
            });
            const { command } = payload;
            let isContinuation = payload.previousContent && payload.log_id;
            const { success, response: tokenResponse, message } = await generateToken(payload);
            if (!success) {
                throw new Error(message);
            }

            if (!isContinuation) {
                setStream();
            }
            setCanContinue(false);
            setIsLoading(true);
            setIsStreaming(true);
            const log_id = tokenResponse[0].log_id;
            const ai_token = tokenResponse[0].ai_token;
            const aiAssistSettingsPromise = updateAIAssistSettings(payload);
            payload.cognito_email = email;
            const response = await fetch(process.env.REACT_APP_AI_ASSIST_STREAM_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    log_id,
                    ai_token,
                    isContinuation,
                    command
                })
            });
            await aiAssistSettingsPromise;
            const streamResponse = response.body;
            if (!streamResponse) {
                setIsLoading(false);
                setIsStreaming(false);
                return;
            }

            const reader = streamResponse.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let content = '';
            setLogId(log_id);

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);
                content = content + chunkValue;
                if (content.indexOf(STOP_INDICATOR) >= 0) {
                    setCanContinue(true);
                    content = content.replace(STOP_INDICATOR, '');
                }
                const streamContent = isContinuation
                    ? payload.previousContent + '\n\n' + content
                    : content;
                setStream(streamContent);
            }
        } catch (ex) {
            console.log(ex);
        } finally {
            setIsLoading(false);
            setIsStreaming(false);
        }
    }

    const isLoading = isLoadingClientSettings || isLoadingMutation;

    return {
        isLoading,
        error: null,
        mutate: generateResponse,
        stream,
        logId,
        canContinue,
        isStreaming
    };
}

function useAIAssistSaveFeedback() {
    const fetchAuth = useFetchAuth();

    async function post(payload) {
        return await fetchAuth('/ai_assist/save_feedback', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post, {
        onError: (err, variables) => {
            Sentry.captureException(err, {
                tags: ['ai_assist_save_feedback'],
                extra: variables
            });
            console.log(err);
        }
    });
    return {
        data,
        error,
        isLoading,
        mutate
    };
}

export default function useAIAssist() {
    const {
        data: response = {},
        error: errorFetch,
        isLoading: isLoadingFetch
    } = useAIAssistFetch();
    const {
        mutate: generateResponse,
        error: errorPost,
        isLoading: isLoadingPost,
        stream,
        logId,
        canContinue,
        isStreaming
    } = useAIAssistPost();
    const {
        mutate: saveFeedback,
        error: errorSaveFeedback,
        isLoading: isLoadingSaveFeedback,
        data: saveFeedbackResult
    } = useAIAssistSaveFeedback();
    const isLoading = isLoadingFetch || isLoadingPost || isLoadingSaveFeedback;
    const error = errorFetch || errorPost || errorSaveFeedback;
    return {
        data: response.data,
        template_groups: response.template_groups,
        error,
        isLoading,
        generateResponse,
        saveFeedback,
        saveFeedbackResult,
        stream,
        logId,
        canContinue,
        isStreaming
    };
}
